/** @format */

import React from "react";
import {
  Container,
  AspectRatio,
  Heading,
  Image,
  Text,
  Box,
} from "@chakra-ui/react";
import HeaderImage from "../static/images/handshake.png";
import SEO from "../components/seo";

const AboutUs = () => {
  return (
    <>
      <AspectRatio ratio={{base: 6 / 4, md: 12 / 4}}>
        <Box bg="#8962b4" mt={5}>
          <Image src={HeaderImage} alt="naruto" objectFit="cover" />
        </Box>
      </AspectRatio>

      <Container my={10}>
        <Heading
          fontSize={{base: "lg", lg: "3xl"}}
          fontWeight="500"
          lineHeight={2}
          m={{base: 10, lg: "200px"}}
        >
          We are{" "}
          <Text as="span" color={"red.500"} fontWeight="bold">
            designers
          </Text>
          ,{" "}
          <Text as="span" color={"pink.500"} fontWeight="bold">
            developers
          </Text>{" "}
          and{" "}
          <Text as="span" color={"yellow.300"} fontWeight="bold">
            architects
          </Text>{" "}
          with different backgrounds and expertise merged into one with the aim
          of building softwares that can empower buisiness.
        </Heading>
      </Container>
    </>
  );
};

export default AboutUs;

export const Head = () => <SEO title="Aphro Tech Consult - About" />;
